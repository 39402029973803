.navbar{
    display: flex;
    justify-content: space-between;       
    /*align-items: center;*/
    padding: 1rem 1.5rem;
    background-color: #a8a7a7;
    background: transparent;
    z-index: 2;
    background-image: url("../../assets/banner/Fundo-Imagem-Principal-Site.gif");
    height: 100vh;
    background-size: cover;
    width: 100%;
}

.logo {
    display: flex;
    position: absolute;
    cursor: pointer;
    width: 4.5rem;
    height: 4rem;
    /*z-index: 2;*/
    align-items: center;
}

.link {
    display: flex;    
    font-size: 1.5rem;    
    z-index: 2;    
    gap: 2rem;     
}

.link a {
    display: flex;    
    background-color:#FFF;
    padding: 0 .7rem;
    font-size: 1.3rem;
    text-decoration: none;
    color: #000;
    font-weight: bold;
    border-radius: .5rem;
    height: 3rem;
    align-items: center;
}


/* RESPONSIVIDADE */

@media (max-width: 450px) {

    body{
        overflow-x: hidden;
    }

    .navbar{
        display: flex;    
        flex: 0 5 0;    
        justify-content: space-between;       
        /*align-items: center;*/
        padding: 1rem 1.5rem;
        background-color: #a8a7a7;
        /*background: transparent;*/
        z-index: 2;
        background-image: url("../../assets/banner/FundoImagemPrincipalSiteRetrato.gif");
        height: 103vh;
        /*background-size: center;       */
        width: 105%;        
    }

    .logo {
        display: flex;
        /*position: absolute;*/
        cursor: pointer;
        width: 3rem;
        height: 3rem;
        /*z-index: 2;*/        
    }
    
    .link {
        display: none;    
        font-size: 1.5rem;    
        z-index: 2;    
        gap: 2rem;     
    }
    
    .link a {
        display: flex;    
        background-color:#FFF;
        padding: 0 .7rem;
        font-size: 1.3rem;
        text-decoration: none;
        color: #000;
        font-weight: bold;
        border-radius: .5rem;
        height: 3rem;
        align-items: center;
    }

    
}

/* RESPONSIVIDADE CELULAR DEITADO A TABLET */

@media (min-width: 577px) and (max-width: 866px) {

    /*body {
        background-color: blue;
    }*/

    body {
        overflow-x: hidden;        
        
    }

    .navbar{
        display: flex;
        justify-content: space-between;       
        /*align-items: center;*/
        padding: 1rem 1.5rem;
        background-color: #a8a7a7;
        background: transparent;
        z-index: 2;
        background-image: url("../../assets/banner/Fundo-Imagem-Principal-Site.gif");
        height: 164vh;
        background-size: cover;
        background-position: center;        
        width: 187%;    
    }




    .logo {
        display: flex;
        /*position: absolute;*/
        cursor: pointer;
        width: 4rem;
        height: 4rem;
        /*z-index: 2;*/
        margin-top: .5rem;
        margin-left: .5rem;
        align-items: center;
    }
    
    .link {
        display: none;   
        font-size: 1.5rem;    
        z-index: 2;    
        gap: 2rem;
        margin-top: 1rem;        
    }
    
    .link a {
        display: flex;    
        background-color:#FFF;
        padding: 0 .7rem;
        font-size: 1.3rem;
        text-decoration: none;
        color: #000;
        font-weight: bold;
        border-radius: .5rem;
        height: 3rem;
        align-items: center;
    }

}