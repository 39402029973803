.textareasbanner{
    display: flex;
    flex-wrap: wrap; 
    gap: 2rem;    
    justify-content: center;
    z-index: -2;
    margin-top: -45rem;
    align-items: center;
    justify-content: center;
    margin-bottom: 7rem;
}

.textcontainer {    
    flex-direction: column;
    text-align: right;
    width: 30%;    
}

.secaotituto{
    color: #FFF;
    margin-bottom: 2rem;
}

.secaotituto span {
    color: #000;
}

.secaotituto, .btn {
    font-size: 2rem; 
    text-align: right;
}

.btn {
    text-decoration: none;
    color: #FFF;
    padding: .7rem 3rem;
    background-color: #c644e5;
    border-radius: 2rem;
    font-weight: bold;
}

.imagebannercontainer {
    border: 1px solid transparent;
    border-radius: 50%;
    padding: 6rem 1rem;
    box-shadow: 0px 0px 15px currentcolor;
    justify-content: center;    
}

/* SOBRE NÓS */

.sectionsobrenos {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: 2rem;
    /*gap: 2rem;*/
    align-items: center;
}

.imgsobrenos {
    display: flex;
    width: 45rem;
    height: 45rem;
    margin-right: -2.5rem;
}

.descricaosobrenos {    
    width: 20%;    
    text-align: center;
    line-height: 2rem;
    margin-left: -2.5rem;
}

.descricaosobrenos h3{
    font-size: 2rem;
}

.descricaosobrenos p {
    font-size: 1.5rem;
}

/* NOSSOS SERVIÇOS */

.nossosservicos {
    display: flex;
    flex-direction: column;    
    width: 50%;
    margin-bottom: 10rem;
    margin: 0 auto;
    margin-bottom: 5rem;
    box-sizing: border-box;
}

.nossosservicos h1 {
    text-align: center;
    font-size: 2.5rem;
    font-weight: bold;
    margin-bottom: 2rem;
}

.servicoscontainer{
    display: flex;
    flex-wrap: wrap;
    margin: 0 auto;
    /*border: 1px solid red;    */
    width: 100%;
    text-align: center;
    gap: 2.5rem;    
}

.servicos {
    display: flex;    
    /*border: 1px solid #000;*/
    align-items: center;
    gap: .5rem;
    padding: 1rem 2.5rem;
    width: 30%;
    justify-content: center;
}

.servicos img {
    display: flex;
    width: 3.5rem;
    height: 3.5rem;
}

.servicos h3 {
    font-weight: bold;
    font-size: 1.5rem;
}

/* NOSSA MISSÃO */

.nossamissaocontainer{    
    justify-content: center;
    text-align: center;
}

.ciculoaberto {
    display: flex;
    width: 2rem;
    height: 2rem;
    border: 2.7rem solid #478ac9;
    padding: 4.5rem 4.5rem;
    border-radius: 50%;
    margin-left: 87rem;
    margin-bottom: -12rem;

}

.ciculoprincipal {
    display: relative;
    margin-top: -5rem;  
    border: 1px solid transparent;
    border-radius: 50%;
    width: 33%;
    /*padding: 5.5rem 0;*/
    padding: 5rem 1rem;
    margin: 0 auto;    
    /*box-shadow: 0px 0px 10px  currentcolor;   */
    box-shadow: 0px 0px 1.5rem  #000;   
}

.ciculoprincipal img {
    display: relative;       
    width: 50rem;
    height: 40rem;    
    z-index: -10;
}

.ciculofechado {
    display: relative;    
    width: 3rem;
    height: 3rem;
    background-color:  #478ac9;
    padding: 7rem 7rem;
    border-radius: 50%;
    margin-left: 55rem;
    margin-top: -15rem;
    z-index: 4;
}

.informacao {
    display: relative;
    margin-top: -12rem;
    width: 20%;
    margin-bottom: 15rem;
    /*border: 1px solid #000;*/
    border-radius: 2rem;
    padding: 1.5rem 1rem;
    margin-left: 82rem;
    padding: 2rem 1rem;
    background-color: #FFF;
    box-shadow: 0px 0px 1.5rem  #000;
    z-index: 5;
    position: absolute;
    margin-bottom: 35rem;
}

.informacao h3 {
    font-size: 2rem;
    margin-bottom: 1rem;
    padding: 0 2rem;
}

.informacao p {    
    width: 100%;    
    text-align: center;
    line-height: 2rem;
    margin-left: -2.5rem;
    font-size: 2rem;
    padding: 0 1rem;
    margin: 0 auto;
    margin-bottom: 1.5rem;
}

.ciculoabertopequeno {
    display: relative;    
    /*background-color:  #478ac9;*/
    padding: 3.5rem 3.5rem;
    border-radius: 50%;
    margin-left: 77rem;
    margin-top: 21rem;
    z-index: 4;
    width: 3rem;
    height: 3rem;
    border: 2rem solid #478ac9;
    /*padding: 4.5rem 4.5rem;*/
    margin-bottom: 4rem;
}


/* VISÃO DETALHADA */

.visaodetalhadacontainer{
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    width: 100%;
    background-color: #478ac9;
    padding: 4rem;
    /*justify-content: center;*/    
}

.visaodetalhadacontainer h1 {
    text-align: center;
    font-size: 2.5rem;
    font-weight: bold;
    margin-bottom: 3rem;
    color: #FFF;
}

.visaodetalhada {
    display: flex;    
    flex-wrap: wrap;    
    width: 60%;    
    gap: 2rem;
    margin-top: 2rem;         
    margin: 0 auto;
    margin-bottom: 2rem;
}

.detalheservicos {  
    display: flex;    
    flex-wrap: wrap;
    width: 22%;    
    padding: .5rem 1rem;    
    /*border: 1px solid #000;*/
    background-color: #FFF;
    border-radius: 2rem;    
}


.detalheservicos img{
    display: flex;    
    width: 3.5rem;
    height: 3.5rem;
}


.detalheservicos h3 {
    margin-top: 1rem;
    align-items: center;
    margin-left: .2rem;
    font-weight: bold;
    font-size: 1.2rem;
}

.detalheservicos p {
    display: flex;
    font-size: 1.1rem;
    margin-bottom: .5rem;
    text-align: center;    
}


/* O QUE NOS DIFERENCIA */

.oquenosdiferenciaocontainer{
    display: flex;
    flex-wrap: wrap;    
    flex-direction: column;
    justify-content: center;
    text-align: center;
    width: 100%;
}

.ciculoabertodiferencia {
    display: relative;
    width: 2rem;
    height: 2rem;
    border: 2.7rem solid #478ac9;
    padding: 4.5rem 4.5rem;
    border-radius: 50%;
    margin-left: 87rem;
    margin-bottom: -12rem;
    z-index: -4;
    margin-top: 5rem;

}

.ciculoprincipaldiferencia {
    display: flex;
    flex-wrap: wrap;
    margin-top: -5rem;
    background-image: url('../assets/img/Nos-diferencia-ilustração-remove-fundo.png');
    background-size: cover;  
    border: 17px solid #478ac9;
    border-radius: 50%;    
    width: 25%;    
    padding: 20rem; 
    /*padding: 1rem .5rem;*/
    /*position: static;*/
    margin: 0 auto;
    /*box-shadow: 0px 0px 10px  currentcolor;   */
    box-shadow: 0px 0px 1.5rem  #000;
    
    
}

.ciculofechadodiferencia {
    display: relative;    
    width: 3rem;
    height: 3rem;
    background-color:  #478ac9;
    padding: 5.5rem 5.5rem;
    border-radius: 50%;
    margin-left: 55rem;
    margin-top: -15rem;
    z-index: 4;
}

.ciculocifraodiferencia{
    display: relative;    
    width: 3rem;
    height: 3rem;
    background-image: url('../assets/img/O\ que\ nos\ diferencia\ 230x230.png');    
    padding: 7.5rem 7.5rem;
    border: 15px solid #478ac9;
    border-radius: 50%;
    margin-left: 80rem;
    margin-top: -10rem;
    z-index: 4;
}

.informacaonosdiferencia {
    display: relative;
    margin-top: -12rem;
    width: 20%;
    margin-bottom: 15rem;
    /*border: 1px solid #000;*/
    border-radius: 2rem;
    padding: 1.5rem 1rem;
    margin-left: 82rem;
    padding: 2rem 1rem;
    background-color: #FFF;
    box-shadow: 0px 0px 1.5rem  #000;
    z-index: 5;
    position: absolute;
    margin-bottom: 35rem;
}

.informacaonosdiferencia h3 {
    font-size: 2rem;
    margin-bottom: 1rem;
    padding: 0 2rem;
}

.informacaonosdiferencia p {    
    width: 100%;    
    text-align: center;
    line-height: 2rem;
    margin-left: -2.5rem;
    font-size: 1.5rem;
    padding: 0 1rem;
    margin: 0 auto;
    margin-bottom: 1.5rem;
}

.ciculoabertopequenodiferencia {
    display: relative;    
    /*background-color:  #478ac9;*/
    padding: 3.5rem 3.5rem;
    border-radius: 50%;
    margin-left: 77rem;
    margin-top: 11rem;
    z-index: 4;
    width: 3rem;
    height: 3rem;
    border: 2rem solid #478ac9;
    /*padding: 4.5rem 4.5rem;*/
    margin-bottom: 4rem;
}




/* RESPONSIVIDADE - CELULAR EM RETRATO */

@media (max-width: 470px) {
   /* body {
        background-color: blue;
    }*/

    /*body {
        overflow-x: hidden;
        
    }*/

    .textareasbanner{
        display: flex;
        flex-wrap: wrap-reverse;
        gap: 2rem;    
        justify-content: center;
        z-index: -2;
        margin-top: -50rem;
        align-items: center;
        justify-content: center;
        margin-bottom: 7rem;
        width: 105%;
    }

    .textcontainer {
        margin-top: .5rem;  
        flex-direction: column;
        text-align: center;
        width: 100%;        
    }
    
    .secaotituto{
        color: #FFF;
        margin-bottom: 2.5rem;
        justify-content: center;
    }
    
    .secaotituto span {
        color: #000;
    }
    
    .secaotituto, .btn {
        font-size: 1.5rem; 
        text-align: center;        
    }
    
    .btn {        
        text-decoration: none;
        color: #FFF;
        padding: .7rem 6rem;
        background-color: #c644e5;
        border-radius: 2rem;
        font-weight: bold;
        
    }
    
    .imagebannercontainer {
        border: 1px solid transparent;
        border-radius: 50%;
        padding: 5rem .5rem;
        box-shadow: 0px 0px 20px currentcolor;
        justify-content: center;
        width: 95%;
        
    }

    .imagebannercontainer img {
        width: 100%;
    }

   
    /* SOBRE NÓS */

.sectionsobrenos {
    /*display: flex;*/
    flex-wrap: wrap;
    /*justify-content: center;*/
    margin-bottom: 3rem;
    /*gap: 2rem;*/
    /*align-items: center;*/
}

.imgsobrenos {
    display: flex;
    /*width: 27rem;*/
    height: 29rem;
    /*width: 30rem;*/
    /*height: 30rem;*/
    width: 100%;
    margin: 0 auto;
    /*margin-left: -2.5rem;*/
}

.descricaosobrenos {
    display: flex;  
    flex-direction: column;
    width: 100%;    
    text-align: center;
    line-height: 2.3rem;
    /*margin-left: -2.5rem;*/
    margin: 0 auto;
    padding: 0 1rem;
}

.descricaosobrenos h3{    
    font-size: 2rem;
}

.descricaosobrenos p {
    font-size: 1.5rem;
}


/* NOSSOS SERVIÇOS */

.nossosservicos {
    display: flex;
    flex-direction: column;    
    width: 100%;
    margin-bottom: 2rem;
    margin: 0 auto;    
    box-sizing: border-box;
}

.nossosservicos h1 {
    text-align: center;
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: 2rem;
}

.servicoscontainer{
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    margin: 0 auto;
    /*border: 1px solid red;    */
    width: 100%;
    text-align: center;
    gap: 1.5rem;    
}

.servicos {
    display: flex;
    flex-wrap: wrap;    
    padding: 1rem 2.5rem;
    width: 100%;
    margin: 0 auto;
    text-align: center;     
}

.servicos img {
    display: flex;
    width: 3.5rem;
    height: 3.5rem;    
}

.servicos h3 {
    display: flex;
    font-weight: bold;
    font-size: 1.5rem;
}



/* NOSSA MISSÃO */

.nossamissaocontainer{    
    justify-content: center;
    text-align: center;
    width: 100%;
    
}

.ciculoaberto {
    display: relative;    
    margin-top: 4rem;
    width: 1rem;
    height: 1rem;
    border: 1.7rem solid #478ac9;
    padding: 2rem 2rem;
    border-radius: 50%;
    margin-left: 17rem;
    margin-bottom: -6rem;

}

.ciculoprincipal {
    display: relative;
    /*position: absolute;*/
    margin-top: .5rem;
    border: 1px solid transparent;
    border-radius: 50%;
    width: 100%;
    /*padding: 7rem;*/
    padding: 4rem 0;    
    margin-left: .6rem;
    /*margin: 0 auto;    */
    /*box-shadow: 0px 0px 10px  currentcolor;   */
    box-shadow: 0px 0px 1.5rem  #000; 
    
}

.ciculoprincipal img {
    display: relative;       
    width: 98%;
    height: 18rem;    
    z-index: -10;
    /*margin-top: 5rem;*/
    /*padding: 5rem;   */
}

.ciculofechado {
    display: relative;    
    width: 3rem;
    height: 3rem;
    background-color:  #478ac9;
    padding: 3rem 3rem;
    border-radius: 50%;
    margin-left: 3rem;
    margin-top: -6rem;
    z-index: 4;
    position: absolute;
}

.informacao {
    display: relative;
    margin-top: -11rem;
    width: 67%;
    margin-bottom: 15rem;
    /*border: 1px solid #000;*/
    border-radius: 2rem;
    /*padding: 1.5rem 1rem;*/
    margin-left: 9.5rem;
    padding: 2rem 0;
    background-color: #FFF;
    box-shadow: 0px 0px 1.5rem  #000;
    z-index: 5;
    position: absolute;
    margin-bottom: 35rem;
}

.informacao h3 {
    display: relative;
    font-size: 1.7rem;
    font-weight: bold;
    margin-bottom: 1rem;
    width: 100%; 
}

.informacao p {    
    width: 100%;    
    text-align: center;
    line-height: 2rem;
    margin-left: -2.5rem;
    font-size: 1.5rem;
    /*padding: 0 1rem;*/
    margin: 0 auto;
    margin-bottom: 1.5rem;
}

.ciculoabertopequeno {
    display: relative;    
    /*background-color:  #478ac9;*/
    padding: 2rem 2rem;
    border-radius: 50%;
    margin-left: 6rem;
    margin-top: 43rem;
    z-index: 4;
    width: 3rem;
    height: 3rem;
    border: 1.5rem solid #478ac9;
    /*padding: 4.5rem 4.5rem;*/
    margin-bottom: 4rem;
}

.informacao a {
    text-decoration: none;
    font-size: 1.2rem;
    color: #FFF;
    padding: .7rem 2.5rem;
    background-color: #c644e5;
    border-radius: 2rem;
    font-weight: bold;
    width: 100%;
}

/* VISÃO PRÉVIA DOS SERVIÇOS */

.visaodetalhadacontainer{
    display: flex;    
    flex-wrap: wrap;
    width: 105%;
    background-color: #478ac9;
    padding: 1rem 1rem;    
}

.visaodetalhadacontainer h1 {
    margin-top: 1rem;
    text-align: center;
    font-size: 2.5rem;
    font-weight: bold;
    margin-bottom: 3rem;
    color: #FFF;
}

.visaodetalhada {
    display: flex;    
    flex-wrap: wrap;    
    width: 100%;    
    gap: 2rem;
    margin-top: 2rem;         
    margin: 0 auto;
    margin-bottom: 5rem;
}

.detalheservicos {  
    display: flex;    
    flex-wrap: wrap;
    width: 100%;    
    padding: .5rem 1rem;    
    /*border: 1px solid #000;*/
    background-color: #FFF;
    border-radius: 2rem;   
    justify-content: center; 
}


.detalheservicos img{
    display: flex;    
    width: 3.5rem;
    height: 3.5rem;
}


.detalheservicos h3 {
    margin-top: 1rem;
    align-items: center;
    margin-left: .2rem;
    font-weight: bold;
    font-size: 1.4rem;
}

.detalheservicos p {
    display: flex;
    font-size: 1.2rem;
    margin-bottom: .5rem;
    text-align: center;    
}


/* O QUE NOS DIFERENCIA */


.oquenosdiferenciaocontainer{
    display: flex;
    justify-content: center;
    text-align: center;
    width: 100%;
    margin-bottom: 25rem;
    
}

.ciculoabertodiferencia {
    display: relative;    
    margin-top: 4rem;
    width: 1rem;
    height: 1rem;
    border: 1.5rem solid #478ac9;
    padding: 2.5rem 2.5rem;
    border-radius: 50%;
    margin-left: 17rem;
    /*margin-bottom: -6rem;*/

}

.ciculoprincipaldiferencia {
    display: relative;    
    margin-top: 6rem;
    background-image: url('../assets/img/Nos-diferencia-ilustração-remove-fundo.png');
    background-size: cover;
    background-position: center;
    border: 18px solid #478ac9;
    border-radius: 50%;
    width: 100%;
    margin-left: .7rem;
    /*margin-right: .5rem;*/
    padding: 12rem .5rem;
    text-align: center;
    justify-content: center;
    /*margin-bottom: 65rem;       */
    /*box-shadow: 0px 0px 10px  currentcolor;   */
    box-shadow: 0px 0px 1.5rem  #000;
}

.ciculofechadodiferencia { 
    display: relative;    
    width: 3rem;
    height: 3rem;
    background-color:  #478ac9;
    padding: 3.5rem 3.5rem;
    border-radius: 50%;
    margin-left: 2rem;
    margin-top: 24rem;
    z-index: 7;
    position: absolute;
    /*margin-bottom: 10rem;*/
}

.ciculocifraodiferencia{
    display: relative;    
    width: 5rem;
    height: 5rem;
    background-image: url('../assets/img/O\ que\ nos\ diferencia\ 230x230.png');
    border: 10px solid #478ac9;
    background-size: cover;
    padding: 4.5rem 4.5rem;
    border-radius: 50%;
    margin-left: 17rem;
    margin-top: 27rem;
    z-index: 4;
    position: absolute;    
}

.informacaonosdiferencia {
    display: relative;        
    width: 90%;
    margin-top: 111rem;
    /*margin-bottom: 15rem;*/
    /*border: 1px solid #000;*/
    border-radius: 2rem;
    /*padding: 1.5rem 1rem;*/
    margin-left: 3rem;
    margin-right: 1.5rem;
    padding: 2rem 0;
    background-color: #FFF;
    box-shadow: 0px 0px 1.5rem  #000;
    z-index: 5;
    /*position: absolute;*/
    /*margin-bottom: 35rem;*/
    
}

.informacaonosdiferencia h3 {
    display: relative;
    font-size: 1.7rem;
    font-weight: bold;
    margin-bottom: 1rem;
    width: 100%; 
}

.informacaonosdiferencia p {    
    width: 100%;    
    text-align: center;
    line-height: 2rem;
    /*margin-left: -2.5rem;*/
    font-size: 1.4rem;
    /*padding: 0 1rem;*/
    margin: 0 auto;
    margin-bottom: 1.5rem;
}


.informacaonosdiferencia a {
    text-decoration: none;
    color: #FFF;
    padding: .7rem 4rem;
    background-color: #c644e5;
    border-radius: 2rem;
    font-weight: bold;
    width: 100%;
}

.ciculoabertopequenodiferencia {
    display: relative;    
    /*background-color:  #478ac9;*/
    padding: 1.5rem 1.5rem;
    border-radius: 50%;
    margin-left: .7rem;
    margin-top: 115rem;
    z-index: 4;
    width: 3rem;
    height: 3rem;
    border: 1.2rem solid #478ac9;
    /*padding: 4.5rem 4.5rem;*/
    /*margin-bottom: 7rem;*/
    position: absolute;
}


.portifoliocontainer{
    margin-top: 15rem;

}



}


/* RESPONSIVIDADE CELULAR DEITADO A TABLET */

@media (min-width: 577px) and (max-width: 866px) {

   /* body {
        background-color: blue;
    }*/

    /*body {
        overflow-x: hidden;        
    }*/

    .textareasbanner{
        display: flex;
        flex-wrap: wrap; 
        gap: 3rem;    
        justify-content: center;
        text-align: center;
        z-index: -2;
        margin-top: -38rem;
        align-items: center;
        justify-content: center;
        margin-bottom: 7rem;
        width: 187%;
    }
    
    .textcontainer {
        /*display: flex;  */
        /*flex-direction: column;*/
        text-align: center;
        width: 35%;
        justify-content: center;
    }
    
    .textareasbanner a{
        font-size: 2rem;
        padding: 2rem;
        display: flex;
        text-decoration: none;
        color: #FFF;
        padding: 1.4rem 4rem;
        background-color: #c644e5;
        border-radius: 3rem;
        font-weight: bold;
        text-align: center;
        justify-content: center;
    }
    .secaotituto{
       /* display: flex;
        flex-direction: column;*/
        color: #FFF;
        margin-bottom: 2rem;
        line-height: 3rem;       
    }
    
    .secaotituto span {
        color: #000;
    }
    
    .secaotituto {
        font-size: 2.5rem; 
        /*text-align: right;*/
    }
    
    
    .imagebannercontainer {
        display: flex;
        border: 1px solid transparent;
        border-radius: 50%;
        padding: 5.5rem 1rem;        
        box-shadow: 0px 0px 15px currentcolor;
        /*justify-content: center;   */
    }

    .imagebannercontainer img{
        width: 35rem;
        height: 25rem;
    }

    
    /* SOBRE NÓS */

.sectionsobrenos {
    display: flex;
    flex-wrap: wrap;
    /*justify-content: center;*/
    margin-bottom: 3rem;    
    /*gap: 2rem;*/
    /*align-items: center;*/
    width: 190%;
    
}



.imgsobrenos {    
    /*width: 27rem;*/
    height: 40rem;
    /*width: 30rem;*/
    /*height: 30rem;*/
    /*width: 100%;*/
    justify-content: center;
    /*margin-left: -2.5rem;*/
}

.descricaosobrenos {    
    width: 50%;    
    text-align: center;
    line-height: 2.5rem;
    /*margin-left: -2.5rem;*/
    margin: 0 auto;
    /*padding: 0 1rem;*/
}

.descricaosobrenos h3{    
    font-size: 2.7rem;
    margin-bottom: 1rem;
}

.descricaosobrenos p {
    font-size: 2rem;
}


/* NOSSOS SERVIÇOS */

.nossosservicos {
    display: flex;
    /*flex-direction: column;    */
    width: 190%;
    margin-bottom: 2rem;
    /*margin: 0 auto;    */
    /*box-sizing: border-box;*/
}

.nossosservicos h1 {
    text-align: center;
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: 2rem;
}

.servicoscontainer{
    display: flex;
    /*flex-direction: column;*/
    /*flex-wrap: wrap;*/
    /*margin: 0 auto;*/
    /*border: 1px solid red;    */
    width:65%;
    /*text-align: center;*/
    /*gap: .5rem;    */
}

.servicos {
    display: flex;     
    padding: 1rem .5rem;
    width: 40%;
    margin: 0 auto;
    /*text-align: center;     
    justify-content: center;*/
    /*border: 1px solid #000;   */    
}

.servicos img {
    display: flex;
    width: 5rem;
    height: 5rem;    
}

.servicos h3 {
    display: flex;
    font-weight: bold;
    font-size: 2rem;
}



/* NOSSA MISSÃO */

.nossamissaocontainer{    
    justify-content: center;
    text-align: center;
    width: 190%;
    margin: 0 auto;
    
}

.ciculoaberto {
    display: relative;    
    margin-top: 5rem;
    width: 1rem;
    height: 1rem;
    border: 2.5rem solid #478ac9;
    padding: 4rem 4rem;
    border-radius: 50%;
    margin-left: 58rem;
    margin-bottom: -10rem;    

}

.ciculoprincipal {
    display: relative;
    /*position: absolute;*/
    margin-top: .5rem;
    border: 1px solid transparent;
    border-radius: 50%;
    width: 53%;
    /*padding: 7rem ;*/
    padding: 6rem 0;    
    /*margin-left: .6rem;*/
    /*margin: 0 auto;    */
    /*box-shadow: 0px 0px 10px  currentcolor;   */
    box-shadow: 0px 0px 1.5rem  #000; 
    
}

.ciculoprincipal img {
    display: relative;       
    /*width: 5rem;*/
    height: 40rem;    
    z-index: -10;
    /*margin-top: 5rem;*/
    /*padding: 5rem;   */
}

.ciculofechado {
    display: relative;    
    width: 4rem;
    height: 4rem;
    background-color:  #478ac9;
    padding: 5rem 5rem;
    border-radius: 50%;
    margin-left: 30rem;
    margin-top: -13rem;
    z-index: 4;
    position: absolute;
}

.informacao {
    display: relative;
    margin-top: -18rem;
    width: 70%;
    margin-bottom: 15rem;
    /*border: 1px solid #000;*/
    border-radius: 2rem;
    /*padding: 1.5rem 1rem;*/
    margin-left: 50rem;
    padding: 2rem 0;
    background-color: #FFF;
    box-shadow: 0px 0px 1.5rem  #000;
    z-index: 5;
    position: absolute;
    margin-bottom: 35rem;
}

.informacao h3 {
    display: relative;
    font-size: 2.5rem;
    font-weight: bold;
    margin-bottom: 1rem;
    width: 100%; 
}

.informacao p {    
    width: 100%;    
    text-align: center;
    line-height: 3rem;
    margin-left: -2.5rem;
    font-size: 2rem;
    /*padding: 0 1rem;*/
    margin: 0 auto;
    margin-bottom: 1.5rem;
}

.ciculoabertopequeno {
    display: relative;    
    /*background-color:  #478ac9;*/
    padding: 3rem 3rem;
    border-radius: 50%;
    margin-left: 46rem;
    margin-top: 31rem;
    z-index: 4;
    width: 5rem;
    height: 5rem;
    border: 2rem solid #478ac9;
    /*padding: 4.5rem 4.5rem;*/
    margin-bottom: 10rem;
}

.informacao a {
    text-decoration: none;
    font-size: 2rem;
    color: #FFF;
    padding: .9rem 7rem;
    background-color: #c644e5;
    border-radius: 2rem;
    font-weight: bold;
    width: 100%;
}

/* VISÃO PRÉVIA DOS SERVIÇOS */

.visaodetalhadacontainer{
    display: flex;    
    flex-wrap: wrap;
    width: 190%;
    background-color: #478ac9;
    padding: 1rem 1rem;    
}


.visaodetalhadacontainer h1 {
    margin-top: 3rem;
    text-align: center;
    font-size: 2.5rem;
    font-weight: bold;
    margin-bottom: 3rem;
    color: #FFF;
}

.visaodetalhada {
    display: flex;    
    flex-wrap: wrap;    
    width: 100%;    
    gap: 2rem;
    margin-top: 2rem;         
    margin: 0 auto;
    margin-bottom: 5rem;
}

.detalheservicos {  
    display: flex;    
    flex-wrap: wrap;
    width: 45%;    
    padding: 1rem 1rem;    
    /*border: 1px solid #000;*/
    background-color: #FFF;
    border-radius: 2rem;   
    margin: 0 auto;
    justify-content: center;
}


.detalheservicos img{
    display: flex;    
    width: 5rem;
    height: 5rem;
}


.detalheservicos h3 {
    margin-top: 1rem;
    align-items: center;
    margin-left: .2rem;
    font-weight: bold;
    font-size: 2.5rem;
}

.detalheservicos p {
    display: flex;
    font-size: 2rem;
    margin-bottom: .5rem;
    text-align: center;    
}


/* O QUE NOS DIFERENCIA */


.oquenosdiferenciaocontainer{
    display: flex;
    flex-wrap: wrap;
    margin-top: 5rem;
    /*justify-content: center;
    text-align: center;*/
    width: 190%;
    margin-bottom: 5rem;
    
}

.ciculoabertodiferencia {
    display: relative;    
    /*margin-top: -2rem;*/
    width: 1rem;
    height: 1rem;
    border: 2.5rem solid #478ac9;
    padding: 4rem 4rem;
    border-radius: 50%;
    margin-left: 32rem;
    /*margin-bottom: -6rem;*/

}

.ciculoprincipaldiferencia {
    display: relative;    
    margin-top: 2rem;
    background-image: url('../assets/img/Nos-diferencia-ilustração-remove-fundo.png');
    background-size: cover;
    background-position: center;
    border: 20px solid #478ac9;
    border-radius: 50%;
    width: 45%;
    margin-left: 1rem;
    /*margin-right: .5rem;*/
    /*padding: 8rem 0;*/
    height: 45rem;
    /*text-align: center;
    justify-content: center;*/
    /*margin-bottom: 65rem;       */
    /*box-shadow: 0px 0px 10px  currentcolor;   */
    box-shadow: 0px 0px 1.5rem  #000;
    /*margin: 0 auto;*/
}

.ciculofechadodiferencia { 
    display: relative;    
    width: 3rem;
    height: 3rem;
    background-color:  #478ac9;
    padding: 5rem 5rem;
    border-radius: 50%;
    margin-left: 5rem;
    margin-top: 38rem;
    z-index: 7;
    position: absolute;
    /*margin-bottom: 10rem;*/
}

.ciculocifraodiferencia{
    display: relative;    
    width: 5rem;
    height: 5rem;
    background-image: url('../assets/img/O\ que\ nos\ diferencia\ 230x230.png');
    border: 10px solid #478ac9;
    background-size: cover;
    padding: 6rem 6rem;
    border-radius: 50%;
    margin-left: 30rem;
    margin-top: 39rem;
    z-index: 4;
    position: absolute;    
}

.informacaonosdiferencia {
    display: relative;        
    width: 95%;
    margin-top: 43rem;
    /*margin-bottom: 15rem;*/
    /*border: 1px solid #000;*/
    border-radius: 2rem;
    /*padding: 1.5rem 1rem;*/
    margin-left: 50rem;
    /*margin-right: 1.5rem;*/
    padding: 2rem 0;
    background-color: #FFF;
    box-shadow: 0px 0px 1.5rem  #000;
    z-index: 5;
    /*position: absolute;*/
    /*margin-bottom: 35rem;*/
    
}

.informacaonosdiferencia h3 {
    display: relative;
    font-size: 2.7rem;
    font-weight: bold;
    margin-bottom: 1rem;
    width: 100%; 
}

.informacaonosdiferencia p {    
    width: 100%;    
    text-align: center;
    line-height: 4rem;
    /*margin-left: -2.5rem;*/
    font-size: 2rem;
    /*padding: 0 1rem;*/
    margin: 0 auto;
    margin-bottom: 2rem;
}


.informacaonosdiferencia a {   
    text-decoration: none;
    color: #FFF;
    padding: 1.3rem 10rem;
    background-color: #c644e5;
    border-radius: 2.5rem;
    font-weight: bold;
    width: 100%;
    font-size: 2rem;
    margin-bottom: 2rem;
}

.ciculoabertopequenodiferencia {
    display: relative;    
    /*background-color:  #478ac9;*/
    padding: 2.5rem 2.5rem;
    border-radius: 50%;
    margin-left: 47rem;
    margin-top: 59rem;
    z-index: 4;
    width: 4rem;
    height: 4rem;
    border: 1.5rem solid #478ac9;
    /*padding: 4.5rem 4.5rem;*/
    margin-bottom: 7rem;
    position: absolute;
}

.portifoliocontainer{
    margin-top: 15rem;
}


}

